<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <div class="m-card text-center">
          <h1 class="o-heading o-heading--xl">Avviso</h1>
          <p>Il nuovo sito per i referti delle strutture è stato trasferito al link:</p>
          <u><a href="https://referti.cdi.it" target="_blank">https://referti.cdi.it</a></u>
          <br>
          <br>
          <p>L'indirizzo email <b>CDISupportB2B@cdi.it</b> resta sempre valido per comunicazioni di natura tecnica.</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "dashboard",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed:{},
  created() {},
  methods: {}
};
</script>
